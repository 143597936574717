import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import * as Sentry from '@sentry/nextjs';

import {
  OrganizationInfoType,
  setOrganizationInfo,
  setOrganizationReset,
  setUnrequiredFields,
} from 'reducers/organizationSlice';

import {
  createToastError,
  createToastSuccess,
} from 'ui-kit/alert-info/controls';
import { getHumanDate } from 'utils/helper';

import { lexics } from 'data/lexics';

import {
  ResetPasswordValueType,
  LoginValuesTypeAndOrg,
} from 'types/authorization';
import {
  RegistrationInputNamesEnum,
  RegistrationValuesType,
} from 'types/registration';
import { OrganizationType } from 'types/organization';
import { User, UserInformation, UserPassword } from 'types/user';
import {
  LearningStatisticsType,
  DetailingStatisticsType,
  VisitDetailingStatisticsType,
} from 'types/statistics';
import { Nullable } from 'types/common';
import { GenericApiRepsonse } from './type';
import { PostNewPasswordType } from 'components/drop-password/types';
import { BackendError } from 'types/api';
import { redirectToErrorPage } from './utils';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_API_URL,
  credentials: 'include',
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseQuery(args, api, extraOptions);

  const error = result.error as BackendError;

  switch (api.endpoint) {
    case 'login':
    case 'getOrganization': {
      break;
    }

    default: {
      if (error) {
        redirectToErrorPage(error);
      }
    }
  }

  return result;
};

const api = createApi({
  tagTypes: ['Organization', 'User', 'DetailingStats'],
  keepUnusedDataFor: 86_400,
  refetchOnReconnect: true,
  reducerPath: 'mainApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOrganization: builder.query<OrganizationType, string | undefined>({
      query: (name) => `organisations/${name}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const {
            data: { id, name, registrationAllowed, allowProfileEdit },
          } = await queryFulfilled;

          dispatch(
            setOrganizationInfo({
              name,
              id,
              registrationAllowed,
              allowProfileEdit,
            }),
          );
          dispatch(api.endpoints.getUnrequiredFields.initiate(id));
        } catch {}
      },
      transformResponse: (data: OrganizationType) => {
        const domains = data.allowedEmailDomains
          ? [...data.allowedEmailDomains, 'gmail.com', 'post-scriptum.ru']
          : ['gmail.com', 'post-scriptum.ru'];

        return {
          ...data,
          allowedEmailDomains: domains,
        };
      },
      providesTags: (_, __, arg) => [{ type: 'Organization', id: arg }],
    }),
    getUnrequiredFields: builder.query<
      RegistrationInputNamesEnum[],
      Nullable<string>
    >({
      query: (orgId) => `organisations/fields/${orgId}`,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUnrequiredFields(data));
        } catch {}
      },
    }),
    getUser: builder.query<User, void>({
      query: () => 'user',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          const {
            organisation: {
              name,
              id: organisationId,
              registrationAllowed,
              allowProfileEdit,
            },
            email,
            firstName,
            secondName,
            id,
          } = data;

          dispatch(
            setOrganizationInfo({
              name,
              id: organisationId,
              registrationAllowed,
              allowProfileEdit,
            }),
          );
          dispatch(api.endpoints.getUnrequiredFields.initiate(id));
          Sentry.setUser({
            email,
            name: `${firstName} ${secondName}`,
            id,
          });
        } catch {}
      },
      providesTags: [{ type: 'User' }],
    }),
    updateUserInformation: builder.mutation<User, UserInformation>({
      query: (body) => {
        return {
          url: 'user-info',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            createToastSuccess(
              lexics.api.response.updateUserInformation.success,
            );
          }

          dispatch(api.util.invalidateTags(['User']));
        } catch (err) {
          createToastError(
            lexics.api.response.updateUserInformation.error.default,
          );
        }
      },
    }),
    updateUserPassword: builder.mutation<User, UserPassword>({
      query: (body) => {
        return {
          url: 'user-password',
          method: 'PATCH',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            createToastSuccess(lexics.api.response.updateUserPassword.success);
          }
        } catch ({ error }) {
          const err = error as FetchBaseQueryError;
          const textField = err.status === 403 ? 403 : 'default';

          createToastError(
            lexics.api.response.updateUserPassword.error[textField],
          );
        }
      },
    }),
    resetPassword: builder.mutation<GenericApiRepsonse, ResetPasswordValueType>(
      {
        query: (body) => ({
          url: 'update-password-email',
          method: 'POST',
          body,
        }),
      },
    ),
    postNewPassword: builder.mutation<GenericApiRepsonse, PostNewPasswordType>({
      query: ({ id, email, newPassword }) => ({
        url: `/update-password/${id}?email=${email}`,
        method: 'PATCH',
        body: {
          newPassword,
        },
      }),
    }),
    getIsResetPasswordLinkExpired: builder.query<
      GenericApiRepsonse,
      string | undefined
    >({
      query: (id) => ({
        url: `update-password-time-check/${id}`,
        method: 'POST',
      }),
    }),
    register: builder.mutation<
      GenericApiRepsonse,
      RegistrationValuesType & Pick<OrganizationInfoType, 'id'>
    >({
      query: (body) => {
        const {
          firstName,
          secondName,
          email,
          birthDate,
          password,
          position,
          confirmPassword,
          location,
          department,
          id,
        } = body;

        return {
          url: 'auth/registration',
          method: 'POST',
          body: {
            firstName,
            secondName,
            email,
            birthDate,
            position,
            password,
            confirmPassword,
            location,
            department,
            organisationId: id,
          },
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch ({ error }) {
          const err = error as FetchBaseQueryError;
          if (err.status === 403) {
            createToastError(lexics.api.response.register.denied);
          }
        }
      },
    }),
    confirmEmail: builder.query<GenericApiRepsonse, string>({
      query: (id) => {
        return {
          url: `confirm/${id}`,
          method: 'PATCH',
        };
      },
    }),
    resendConfirmEmail: builder.mutation<GenericApiRepsonse, string>({
      query: (id) => {
        return {
          url: `resend-email/${id}`,
          method: 'POST',
        };
      },
    }),
    getLearningStatistics: builder.query<LearningStatisticsType, void>({
      query: () => 'learning-stats/user-results',
    }),
    getDetailingStatistics: builder.query<DetailingStatisticsType, void>({
      query: () => 'detailing-stats/user-results',
    }),
    getDetailingStatisticsById: builder.query<
      VisitDetailingStatisticsType,
      string | string[] | undefined
    >({
      query: (id) => `detailing-stats/user-results/${id}`,
      transformResponse: (data: VisitDetailingStatisticsType) => {
        return {
          ...data,
          visitDate: getHumanDate(data.visitDate),
        };
      },
      providesTags: (_, __, arg) => [
        { type: 'DetailingStats', id: String(arg) },
      ],
    }),
    login: builder.mutation<{ maven_id: string }, LoginValuesTypeAndOrg>({
      query: (body) => {
        return {
          url: 'auth/login',
          method: 'POST',
          body,
        };
      },
      async onQueryStarted(_, { queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch {}
      },
    }),
    logout: builder.mutation<{}, void>({
      query: () => 'auth/logout',
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(setOrganizationReset());

        dispatch(api.util.resetApiState());
      },
      invalidatesTags: [{ type: 'User' }],
    }),
  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,

  useRegisterMutation,
  useResetPasswordMutation,
  useGetIsResetPasswordLinkExpiredQuery,

  useConfirmEmailQuery,
  useResendConfirmEmailMutation,

  usePostNewPasswordMutation,

  useGetLearningStatisticsQuery,
  useGetDetailingStatisticsQuery,

  useGetDetailingStatisticsByIdQuery,

  useGetUserQuery,
  useLazyGetUserQuery,
  useUpdateUserInformationMutation,
  useUpdateUserPasswordMutation,

  useGetOrganizationQuery,
  useLazyGetOrganizationQuery,
} = api;

export default api;
