import Router from 'next/router';
import { BackendError } from 'types/api';
import { Links } from 'types/links';
import { resctrictedPages } from 'components/layout/constants';

export const redirectToErrorPage = ({ status }: BackendError) => {
  const isCheck = Number(status) >= 400;

  if (isCheck) {
    switch (status) {
      case 401: {
        if (resctrictedPages.includes(window.location.pathname)) {
          Router.push(Links.organization);
        }

        break;
      }

      case 404: {
        window.location.assign(`${window.location.origin}/404`);

        break;
      }

      case 403: {
        break;
      }

      case 503: {
        window.location.assign(`${window.location.origin}/503`);

        break;
      }

      default: {
        window.location.assign(`${window.location.origin}/unknown`);
      }
    }
  }
};
